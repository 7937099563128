.myphoto-img-wrap{
    position: relative;
    border-radius: 25px;
    overflow: hidden;
}

.myphoto-img{
    position: relative;
    display: block;
}

.trash-p{
    position: absolute;
    bottom: 4px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    width: 50px;
    height: 40px;
    border-top-left-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding-top: 8px !important;
    padding-left: 5px !important;
    cursor: pointer;
}

.myphoto-img::after{
    content: '';
    width: 100px;
    height: 100px;
    background-color: red;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 55;
}

.myphotos-label{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed var(--pink);
    border-radius: 25px;
    overflow: hidden;
    background-color: rgb(233, 233, 233);
    width: 100%;
    margin: auto;
    aspect-ratio: 1/1;
}