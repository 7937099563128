.hero-secton{
    /* min-height: 92vh; */
    /* background: transparent; */
    background-image: url(../../../public/assets/hero-bg.png) !important;
    background-size: 1500px;
    background-repeat: no-repeat;
    background-position: 350px 105px;
    padding-bottom: 35px;
    /* margin-bottom: 20px; */
}

.hero-secton-mob{
    display: none;
}

.quote-img-wrap{
    width: 30%;
}

.hero-text{
    font-size: 70px;
    width: min(90%, 800px);
}

.search-box-mob-wrap{
    display: none;
}

.search-box{
    width: 55vw !important;
}

.static-img{
    width: 50%;
}

.step-count{
    font-size: 10px;
}

.loation-popup-wrap{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.loation-popup{
    width: min(100%, 500px);
    height: 70vh;
    overflow-y: auto;
}
.loation-popup::-webkit-scrollbar{
    display: none;
}

.chat-icon{
    width: min(100px, 15%);
}

.need-help-wrap{
    width: min(500px, 100%);
    border-radius: 20px;
}

.chatnow-btn{
    background-color: #62cd34;
    color: white;
}
.chatnow-btn:hover{
    background-color: #53b828;
    color: white;
}

@media screen and (width < 780px) {
    .hero-secton{
        display: none;
    }
    .hero-secton-mob{
        display: block;
    }
    .hero-text{
        font-size: 38px;
        width: 100%;
    }
    .hero-para{
        font-size: 14px !important;
    }
    .search-box-wrap{
        display: none;
    }
    .search-box-mob-wrap{
        display: block;
    }
    .hero-img{
        display: none;
    }
    .search-box-mob-wraper{
        /* background-image: url(../../../public/assets/new-heroimg.jpeg); */
        background-image: none;
        /* min-height: 70vh; */
        padding-bottom: 20vw !important;
        background-position: top center;
        background-size: 150%;
        background-repeat: no-repeat;
    }
    .hero-text{
        padding-top: 20vw !important;
    }
    .steps-img{
        display: none;
    }
    .steps-card{
        text-align: start !important;
    }
    .steps-card div{
        justify-content: flex-start !important;
    }
    .hero-secton-mob{
        background-image: url(../../../public/assets/new-heroimg.jpg);
        background-position: top center;
        background-size: 120%;
        background-repeat: no-repeat;
    }
    .hero-secton-mob .container{
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(6px);
        min-height: 60vh;
    }
}

@media screen and (width > 1500px) {
    .hero-secton{
        /* min-height: 92vh; */
        /* background: transparent; */
        background-image: url(../../../public/assets/hero-bg.png) !important;
        background-size: 1500px;
        background-repeat: no-repeat;
        background-position: 550px 105px;
    }
}

@media screen and (width > 1700px) {
    .hero-secton{
        /* min-height: 92vh; */
        /* background: transparent; */
        background-image: url(../../../public/assets/hero-bg.png) !important;
        background-size: 1500px;
        background-repeat: no-repeat;
        background-position: 700px 105px;
    }
}