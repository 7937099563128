.pages-dash{
    height: 95vh;
    position: relative;
    overflow-y: auto;
}

.datefilter-inpu{
    width: max-content;
}

.datefilter{
    width: 120px;
}

.datefilter-options{
    position: absolute;
    z-index: 555;
}

.datepicker{
    width: 100% !important;
}

.react-datepicker__month-container {
    float: left;
    width: 100%;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem;
    background: var(--light-pink);
    border-bottom: 1px solid var(--pink);
    width: 100%;
}

.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid var(--pink) !important;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    width: unset;
    line-height: initial;
    border-radius: 15px !important;
    overflow: hidden;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: var(--pink) !important;
    color: #fff;
}

@media screen and (width < 780px) {
    .pages-dash{
        height: auto;
        position: relative;
        overflow-y: auto;
        padding-top: 50px;
    }
    .react-datepicker {
        font-family: "Helvetica Neue", helvetica, arial, sans-serif;
        font-size: 0.8rem;
        background-color: #fff;
        color: #000;
        border: 1px solid var(--pink) !important;
        border-radius: 0.3rem;
        display: inline-block;
        position: relative;
        width: 100% !important;
        line-height: initial;
        border-radius: 15px !important;
        overflow: hidden;
    }
    .dadepicker-wrap{
        display: block !important;
    }
    .datefilter-option-wrap{
        display: flex;
        flex-wrap: wrap;
    }
    .datefilter{
        width: max-content !important;
    }
}