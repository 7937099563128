.profile-container{
    width: min(100%, 600px) !important;
    margin: auto;
}

.profile-image{
    width: 20%;
    aspect-ratio: 1/1;
}

.confirm-popup-wrap{
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 55;
    background-color: rgba(0, 0, 0, 0.3);
}

.noti-wrap{
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
}

@media screen and (width < 780px) {
    .profile-container{
        box-shadow: 0px 0px 0px white !important;
        width: 100% !important;
        margin: auto;
    }
}

@media screen and (width > 780px) {
    .profile-container{
        /* box-shadow: 0px 0px 0px white !important; */
        width: 600px !important;
        margin: auto;
    }
}