.indicator{
    background-color: rgb(232, 232, 232);
    height: 4px;
}

.active-indicator{
    background-color: var(--pink);
}

.forms{
    display: none;
}

.form-container{
    width: min(100%, 500px);
}

.lang-drop{
    background-color: rgb(232, 232, 232);
}

.lang-drop:hover{
    background-color: rgb(207, 207, 207);
}

.course-drop-wrap{
    height: 300px;
    overflow: auto;
}

.next-button-wrap{
    position: fixed;
    bottom: 10px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.stay-with-fam label{
    font-size: 14px;
}

.next-btn{
    letter-spacing: 2px;
    width: min(100%, 500px) !important;
}

.profile-img-label{
    width: min(80%, 400px);
    aspect-ratio: 1/1;
    border: 2px dashed var(--pink);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    background-color: #f1f1f1;
    margin: auto;
}

.my-photos{
    columns: 2;
    position: relative;
}

.error-wrap {
    position: fixed;
    top: 20px;
    left: 0px;
}

.form-popup-wrap{
    background-color: rgba(0, 0, 0, 0.433);
    backdrop-filter: blur(5px);
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-popup{
    background-color: white;
    border-radius: 25px;
}

.small-txt{
    font-size: 12px;
}

.form-check-input:checked{
    background-color: var(--pink);
}

.help-wrap{
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 999;
}

.imagelabel{
    width: min(100%, 400px);
    aspect-ratio: 1/1;
    border: 2px dashed var(--pink);
    border-radius: 30px;
}

@media screen and (width < 780) {
    .my-photos{
        columns: 1;
    }
}