.listing-card-img{
    width: 45%;
    aspect-ratio: 1/1;
}

.listing-card-data{
    width: 55%;
}

.listing-card-name{
    line-height: 21px;
}

.listing-card-prof{
    font-size: 14px;
}

.listing-card-city, .listing-card-age{
    font-size: 12px;
}

/* .request-btn-wrap{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: rgb(255, 209, 224);
} */

.decline-btn{
    background-color: #ffded7;
    color: #b56351;
}
.decline-btn:hover{
    background-color: #e4d2cf;
    color: #b56351;
}

.accept-btn{
    background-color: #e9ffde;
    color: #75ad5b;
}
.accept-btn:hover{
    background-color: #d0edc2;
    color: #75ad5b;
}

.pending-btn{
    background-color: #fffcbf;
    color: #cdc533;
}
.pending-btn:hover{
    background-color: #fffbac;
    color: #cdc533;
}

.card-out-info{
    font-size: 13px;
}