.main-reset-div{
    width: min(95%, 500px);
}

.small-alert{
    font-size: 10px;
}

.done-pop-wrap{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.323);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
}

.done-popup{
    width: min(95%, 500px);
}