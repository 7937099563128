.main-adminlogin{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('https://www.zmo.ai/wp-content/uploads/2023/09/plain-white-background-cnw05vrcsznafb3o-scaled.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.login-pop{
    width: min(95%, 400px);
}