.login-contianer, .forgetpass-popup{
    width: min(95%, 500px);
    border-radius: 15px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.17);
}

.forgetpass-pop-wrap{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.327);
    backdrop-filter: blur(5px);
}