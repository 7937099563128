.indicator{
    background-color: rgb(232, 232, 232);
    height: 4px;
}

.active-indicator{
    background-color: var(--pink);
}

.forms{
    display: none;
}

.form-container{
    width: min(100%, 500px);
}

.lang-drop{
    background-color: rgb(232, 232, 232);
}

.lang-drop:hover{
    background-color: rgb(207, 207, 207);
}

.course-drop-wrap{
    height: 300px;
    overflow: auto;
}

.next-button-wrap{
    position: sticky;
    top: 60px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.stay-with-fam label{
    font-size: 14px;
}

.next-btn{
    letter-spacing: 2px;
    width: min(100%, 500px) !important;
}

.profile-img-label{
    width: min(80%, 400px);
    aspect-ratio: 1/1;
    border: 2px dashed var(--pink);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    background-color: #f1f1f1;
    margin: auto;
}