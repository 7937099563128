.edit-pop-wrap{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    z-index: 5555;
}

.edit-pop{
    width: min(95%, 400px);
}