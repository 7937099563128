.search-popup-wrap, .advanced-filter-wrap{
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(1, 1, 1, 0.34);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.advanced-filter{
    width: min(100%, 500px);
    background-color: white;
}

.state-wraper{
    position: relative;
    width: 100%;
}

.state-drop-wrap{
    position: absolute;
    width: 100%;
    height: 200px;
    overflow-y: auto;
    z-index: 55;
}

.search-popup{
    position: relative;
    width: 600px;
    min-height: 85vh;
    border-radius: 25px;
}

.search-btn-wrap{
    position: absolute;
    bottom: 10px;
    left: 0px;
    width: 100%;
}

.backbtn-detail-explore{
    position: absolute !important;
    left: unset !important;
    right: 10px !important;
}

.comunity-filter-tab{
    overflow-x: auto;
}

.comunity-filter-tab::-webkit-scrollbar{
    display: none;
}

.comunity-filter-tab-wrap{
    flex-wrap: nowrap;
    position: sticky;
    top: 0px;
    background-color: white;
    padding-bottom: 10px;
    z-index: 999;
}

@media screen and (width < 780px) {
    .search-popup{
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
    .comunity-filter-tab-wrap{
        flex-wrap: wrap;
    }
    .location-filter-btn{
        width: 100%;
    }
}