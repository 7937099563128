.signup-pop-wrap{
    width: min(100%, 500px);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.17);
}

.signuppop-bg{
    width: 100vw;
    height: 100vh;
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.455);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: end;
    justify-content: center;
    z-index: 1001;
}

.signuppop-bg .signup-comp-wrap{
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

@media screen and (width < 800px) {
    .signup-comp-wrap{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}