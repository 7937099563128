.matched-card-img{
    aspect-ratio: 1/1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.heart-div{
    position: relative;
    aspect-ratio: 1/1;
    width: 20%;
}

.heart-2{
    color: #f19ccf;
    right: 10px;
    top: 15px;
    transform: rotate(10deg);
}

.heart-1{
    z-index: 2;
    left: 10px;
    bottom: 15px;
    transform: rotate(-10deg);
}

.heart-1, .heart-2{
    position: absolute;
    font-size: 35px;
}

.user-1, .user-2{
    width: 40%;
}

.matched-name{
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9));
}

@media screen and (width > 1050px) {
    .heart-2{
        color: #f19ccf;
        right: 20px;
        top: 30px;
        transform: rotate(10deg);
    }
    
    .heart-1{
        z-index: 2;
        left: 20px;
        bottom: 30px;
        transform: rotate(-10deg);
    }
}