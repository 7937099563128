.main-about-head{
    background-image: url(../../../public/assets/reseption-bg.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.main-about-head-wrap{
    min-height: 60vh;
    width: 100%;
    background-color: rgba(235, 143, 247, 0.6);
}

.main-about-heading{
    font-size: 60px;
}

@media screen and (width < 700px) {
    .main-about-heading{
        font-size: 45px;
    }
}