:root{
  --skin: #FFEBB2;
  --skin-hover: #dfc98a;
  --light-pink: #FBEDE3;
  --light-pink-hover: #fcc2ff;
  --pink: #D862BC;
  --pink-hover: #bd4ba2;
  --purple: #8644A2;
  --purple-hover: #73348e;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: var(--body-font-regular);
}

/* -------------------------- skin utils -------------------------- */
.bg-brand-skin{
  background-color: var(--skin) !important;
}
.text-brand-skin{
  color: var(--skin) !important;
}
.border-brand-skin{
  border-color: var(--skin) !important;
}
.btn-brand-skin{
  background-color: var(--skin);
  color: black;
}
.btn-brand-skin:hover{
  background-color: var(--skin-hover);
}
.btn-outline-brand-skin{
  border: 1px solid var(--skin);
  color: var(--skin);
}
.btn-outline-brand-skin:hover{
  background-color: var(--skin);
  color: black;
}

/* -------------------------- light pink utils -------------------------- */
.bg-brand-light-pink{
  background-color: var(--light-pink) !important;
}
.text-brand-light-pink{
  color: var(--light-pink) !important;
}
.border-brand-light-pink{
  border-color: var(--light-pink) !important;
}
.btn-brand-light-pink{
  background-color: var(--light-pink);
  color: white;
}
.btn-brand-light-pink:hover{
  background-color: var(--light-pink-hover);
  color: white;
}
.btn-outline-brand-light-pink{
  border: 1px solid var(--light-pink);
  color: var(--light-pink);
}
.btn-outline-brand-light-pink:hover{
  background-color: var(--light-pink);
  color: white;
}

/* -------------------------- pink utils -------------------------- */
.bg-brand-pink{
  background-color: var(--pink) !important;
}
.text-brand-pink{
  color: var(--pink) !important;
}
.border-brand-pink{
  border-color: var(--pink) !important;
}
.btn-brand-pink{
  background-color: var(--pink);
  color: white;
}
.btn-brand-pink:hover{
  background-color: var(--pink-hover);
  color: white;
}
.btn-outline-brand-pink{
  border: 1px solid var(--pink);
  color: var(--pink);
}
.btn-outline-brand-pink:hover{
  background-color: var(--pink);
  color: white;
}

/* -------------------------- purple utils -------------------------- */
.bg-brand-purple{
  background-color: var(--purple) !important;
}
.text-brand-purple{
  color: var(--purple) !important;
}
.border-brand-purple{
  border-color: var(--purple) !important;
}
.btn-brand-purple{
  background-color: var(--purple);
  color: white;
}
.btn-brand-purple:hover{
  background-color: var(--purple-hover);
  color: white;
}
.btn-outline-brand-purple{
  border: 1px solid var(--purple);
  color: var(--purple);
}
.btn-outline-brand-purple:hover{
  background-color: var(--purple);
  color: white;
}


/* /////////////////////////////////////////// Util Classes /////////////////////////////////////////// */

.rounded-brand-sm{
  border-radius: 15px !important;
}
.rounded-brand{
  border-radius: 25px !important;
}

.w-max-content{
  width: max-content !important;
}

.heading{
  font-size: 30px;
  font-family: var(--body-font-bold);
}

.overflow-hidden{
  overflow: hidden !important;
}

.mycontainer{
  width: 90%;
  padding: 10px;
  margin: auto;
}

.cursor-pointer{
  cursor: pointer !important;
}

/* .fa-check-circle{
  color: #57ADF5 !important;
} */

.verified-img{
  width: 20px !important;
  margin-top: -3px !important;
}

.fs-sm{
  font-size: 14px;
}

.fs-esm{
  font-size: 12px;
}

@media screen and (width < 780px) {
  .heading{
    font-size: 25px;
  }
  .mycontainer{
    width: 100%;
  }
}

@media screen and (width > 1200px) {
  .mycontainer{
    width: 1200px;
  }
}