.dashmenu-wrap{
    min-height: 95vh;
}

.ham-wraper{
    display: none;
}

@media screen and (width < 780px) {
    .dashmenu-wrap{
        position: fixed;
        top: 48px;
        min-height: 92vh;
        z-index: 99;
        margin-left: -100vw;
        transition: 0.3s;
    }
    .ham-wraper{
        display: block;
        position: fixed;
        top: 0px;
        padding-top: 10px !important;
        z-index: 99;
    }
    .ham{
        width: 40px;
        height: 10px;
        background-color: var(--pink);
        border-radius: 20px;
        margin-bottom: 5px;
    }
}