.backbtn-detail{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgba(49, 49, 49, 0.235);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 10px;
    left: 10px;
}

.report-detail{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgba(49, 49, 49, 0.235);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 10px;
    right: 10px;
}

.report-para{
    position: fixed;
    top: 50px;
    right: 10px;
}

.report-popup-wrap{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    z-index: 999;
}

.report-popup{
    width: min(100%, 500px);
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    min-height: 60vh;
    max-height: 90vh;
}

.reasons-wrap{
    max-height: 65vh;
    overflow-y: auto;
}

.cross-wrap{
    width: min(100%, 500px);
}

.reasons-wrap::-webkit-scrollbar{
    width: 2px;
    border-radius: 20px;
    background-color: rgb(239, 239, 239);
}

.reasons-wrap::-webkit-scrollbar-thumb{
    background-color: lightgray;
}

.detail-img{
    border-radius: 25px;
}

.detail-profile-img{
    aspect-ratio: 1/1;
    overflow: hidden;
}

.detail-images{
    columns: 3;
    gap: 15px;
}

.popupWrap{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-wraper{
    width: min(100%, 500px);
}

.img-popup-cross{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.13);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 7px !important;
    cursor: pointer;
}

.bottom-btn-wrap{
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 999;
}

.bottom-btn-wrap .row{
    border-top-left-radius: 40px !important;
    border-top-right-radius: 40px !important;
    box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.129);
    width: min(100%, 600px) !important;
}

.propref-head{
    font-size: 12px;
}

.sudo-blur{
    position: relative;
}
.sudo-blur::after{
    content: '';
    width: 90%;
    height: 100%;
    right: 0px;
    top: 0px;
    position: absolute;
    backdrop-filter: blur(3px);
}

.sudo-blur-img{
    position: relative;
}
.sudo-blur-img::after{
    content: '';
    width: 100%;
    height: 100%;
    right: 0px;
    position: absolute;
    backdrop-filter: blur(45px);
}

.img-raper{
    position: relative;
}

.overlay-img, .trans-img{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 555;
    /* background-color: rgba(255, 255, 255, 0.246); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.overlay-text{
    font-size: 12px;
}

.trans-img img{
    width: 100% !important;
    height: 100% !important;
}

.overlay-img img{
    width: 80px !important;
    aspect-ratio: 1/1 !important;
}

.del-pop-wrap{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
}

.del-pop{
    width: min(95%, 400px);
}

.pop-notifi-wrap{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
}

@media screen and (width < 780px) {
    .detail-images{
        columns: 2;
    }
    .detail-img{
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }
    .main-detail-wrap{
        padding: 0px;
    }
}