.nav-li:hover{
    color: var(--pink);
}

.navbar-wrap{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.mob-ul-wrap{
    display: none;
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -1px 20px #0000003b;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    z-index: 999;
}

.nav-req{
    position: relative;
}

.nav-req::after{
    content: attr(data-count);
    position: absolute;
    top: 0px;
    left: 10px;
    width: 20px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 900;
    color: white;
    background-color: var(--pink);
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes activeTabAni {
    0%{
        width: 0px;
    }100%{
        width: 110px;
    }
}

.active-mob-tab{
    animation: activeTabAni 0.2s ease-in;
    overflow: hidden;
    background-color: var(--light-pink);
}

@media screen and (width < 780px) {
    .nav-ul-wrap{
        display: none;
    }
    .mob-ul-wrap{
        display: block;
    }
}